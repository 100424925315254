import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { concatPagination } from '@apollo/client/utilities'
import { useMemo } from 'react'

import { API_URL } from '../config/settings'

let apolloClient: ApolloClient<any>

const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: API_URL,
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
      },
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            newsCollection: concatPagination(),
            casestudyCollection: concatPagination(),
          },
        },
      },
    }),
  })

export const initializeApollo = (
  initialState: ApolloClient<any> | null = null
) => {
  const localApolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = localApolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    localApolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return localApolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = localApolloClient

  return localApolloClient
}

export const useApollo = (initialState: ApolloClient<any>) => {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
